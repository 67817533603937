import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import AboutUs from './components/aboutUs/AboutUs';
import Home from './components/home/Home';
import Laws from './components/laws/Laws';
import OffersAndCritics from './components/offersAndCritics/OffersAndCritics';
import RegisterComplaints from './components/registerComplaints/RegisterComplaints';
import RepetitiveQuestions from './components/repetitiveQuestions/RepetitiveQuestions';
import ContactUs from './components/contactUs/ContactUs';
import WhyToomarPage from './components/whyToomar/WhyToomar';
import OrderGuide from './components/orderGuide/OrderGuide';
import FollowUpOrder from './components/followUpOrder/FollowUpOrder';
import Magazine from './components/magazine/Magazine';
import MagazineDetails from './components/magazine/MagazineDetails';
import Gallery from './components/gallery/Gallery';
import FollowUpLastOrder from './components/followUpLastOrder/FollowUpLastOrder';
import Product from './components/product/Product';
import BuyProcess from './components/buyProcess/BuyProcess';
import Cart from './components/cart/Cart';
import Favorite from './components/favorite/Favorite';

function App() {
  
  return (
    <div id={'top'} style={{position: 'relative'}}>
      <Routers/>
    </div>
  );
}

function Routers() {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='register-complaints' element={<RegisterComplaints/>}/>
          <Route exact path='about-us' element={<AboutUs/>}/>
          <Route exact path='offers-critics' element={<OffersAndCritics/>}/>
          <Route exact path='repetitive-questions' element={<RepetitiveQuestions/>}/>
          <Route exact path='laws' element={<Laws/>}/>
          <Route exact path='contact-us' element={<ContactUs/>}/>
          <Route exact path='why-toomar' element={<WhyToomarPage/>}/>
          <Route exact path='order-guide' element={<OrderGuide/>}/>
          <Route exact path='followup-order' element={<FollowUpOrder/>}/>
          {/* <Route path='followup-last-order/:id' element={<FollowUpLastOrder/>}/> */}
          <Route exact path='magazine' element={<Magazine/>}/>
          <Route exact path='magazine/:id' element={<MagazineDetails/>}/>
          <Route exact path='gallery' element={<Gallery/>}/>
          <Route exact path='gallery/:id/' element={<Gallery/>}/>
          <Route exact path='gallery/:id/:name' element={<Gallery/>}/>
          <Route exact path='product/:id' element={<Product/>}/>
          <Route exact path='buy-process/:id' element={<BuyProcess/>}/>
          <Route exact path='cart' element={<Cart/>}/>
          <Route exact path='favorite' element={<Favorite/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default App;
