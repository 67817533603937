import React, { useEffect, useState } from 'react';
import Title from '../Utils/Title';
import './Home.scss';
import ToomarUnderlineSmooth from '../../assets/toomarUnderlineSmooth.svg';
import { connect } from 'react-redux';
import { getAllProducts } from '../../redux/actions';
import { Link } from 'react-router-dom';
function ToomarGallery({galleryCategories, ...props}) {

    const [whichMenu, setWhichMenu] = useState(123);
    const [menuItem, setMenuItems] = useState();

    useEffect(() => {
        props.getAllProducts({category: `${whichMenu}`}, (data) => setMenuItems(data.data.results));
    }, [])

    const handleGetWhichMenu = (id) => {
        setWhichMenu(id);
        setMenuItems([])
        props.getAllProducts({category: `${id}`, ordering:"-score"}, (data) => setMenuItems(data.data.results));
    }

    return (
        <div className={'toomarGalleryContainer'}>
            <Title goldText={'Toomar’s'} blackText={'Gallery'}/>
            <div>
                <div>
                    {galleryCategories.map(item => 
                        <div onClick={() => handleGetWhichMenu(item.id)}>
                            <p className={whichMenu == item.id ? 'isActive' : 'isNotActive'}>
                                {item.name}
                            </p>
                            {whichMenu == item.id ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                        </div>
                    )}
                    {/* <div onClick={() => setWhichMenu(1)}>
                        <p className={whichMenu == 1 ? 'isActive' : 'isNotActive'}>
                            کارت عروسی
                        </p>
                        {whichMenu == 1 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    <div onClick={() => setWhichMenu(2)}>
                        <p className={whichMenu == 2 ? 'isActive' : 'isNotActive'}>
                            کارت عروسی دیجیتال
                        </p>
                        {whichMenu == 2 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    <div onClick={() => setWhichMenu(3)}>
                        <p className={whichMenu == 3 ? 'isActive' : 'isNotActive'}>
                            دفتر بله برون
                        </p>
                        {whichMenu == 3 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div>
                    <div onClick={() => setWhichMenu(4)}>
                        <p className={whichMenu == 4 ? 'isActive' : 'isNotActive'}>
                            گیفت عروسی
                        </p>
                        {whichMenu == 4 ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                    </div> */}
                </div>
                <div>
                    {menuItem && [...menuItem]?.splice(0, 5).map((item, index) => (
                        index == 3 ?
                        <div>
                            <div>
                                <Link to={`/product/${menuItem[index-1]?.id}`}>
                                    {menuItem[index - 1]?.cover && <img src={menuItem[index - 1]?.cover} alt={'wedding-cart'}/>}
                                    <div>
                                        <p>
                                            {menuItem[index - 1]?.category_name}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to={`/product/${menuItem[index]?.id}`}>
                                    {menuItem[index].cover && <img src={menuItem[index].cover} alt={'wedding-cart'}/>}
                                    <div>
                                        <p>
                                            {menuItem[index]?.category_name}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to={`/product/${menuItem[index+1]?.id}`}>
                                    {menuItem[index + 1]?.cover && <img src={menuItem[index + 1]?.cover} alt={'wedding-cart'}/>}
                                    <div>
                                        <p>
                                        {menuItem[index + 1]?.category_name}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link to={`/product/${menuItem[index+2]?.id}`}>
                                    {menuItem[index + 2]?.cover && <img src={menuItem[index + 2]?.cover} alt={'wedding-cart'}/>}
                                    <div>
                                        <p>
                                            {menuItem[index + 2]?.category_name}
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        </div> 
                        : index == 4 || index == 5 ? null :
                        <div>
                            <Link to={`/product/${item?.id}`}>
                                {item?.cover && <img src={item?.cover} alt={'wedding-cart'}/>}
                                <div>
                                    <p>
                                        {item?.category_name}
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))}
                    
                    {/* <div>
                        <img src={Test1} alt={'کارت عروسی'}/>
                        <div>
                            <p>
                                آبرنگ
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src={Test1} alt={'کارت عروسی'}/>
                        <div>
                            <p>
                                آبرنگ
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={Test1} alt={'کارت عروسی'}/>
                            <div>
                                <p>
                                    آبرنگ
                                </p>
                            </div>
                        </div>
                        <div>
                            <img src={Test1} alt={'کارت عروسی'}/>
                            <div>
                                <p>
                                    آبرنگ
                                </p>
                            </div>
                        </div>
                        <div>
                            <img src={Test1} alt={'کارت عروسی'}/>
                            <div>
                                <p>
                                    آبرنگ
                                </p>
                            </div>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
    )
}

export default connect(null, { getAllProducts })(ToomarGallery);