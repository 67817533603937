import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import './Home.scss';
import WeddingRing from '../../assets/weddingRing.svg';
import Swiper1 from '../../assets/swiper1.svg';
import Swiper2 from '../../assets/swiper2.svg';
import Swiper3 from '../../assets/swiper3.svg';
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import SwiperCore, {
  Navigation,Pagination,Mousewheel
} from 'swiper';
import { Link } from 'react-router-dom';

// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel]);

function ToomarCardsSwiper({data, ...props}) {
    return (
        <div className={'toomarCardsSwiperContainer'}>
           <Swiper pagination={{dynamicBullets: true}} cssMode={true} navigation={true} loop={true}  mousewheel={true} className={"toomarCardsSwiper"}>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <div>
                                    <img src={WeddingRing} alt={'wedding-cart'}/>
                                    <span>
                                    Toomar <span>Wedding</span> Invitation
                                    </span>
                                    <p>
                                    The Largest Online store for buying Wedding Invitation and Wedding Items
                                    </p>
                                    <button>
                                    Go To Gallery
                                    </button>
                            </div>
                            <img src={Swiper2} alt={'wedding-cart'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <div>
                                    <img src={WeddingRing} alt={'wedding-cart'}/>
                                    <span>
                                    Toomar <span>Wedding</span> Invitation
                                    </span>
                                    <p>
                                    The Largest Online store for buying Wedding Invitation and Wedding Items
                                    </p>
                                    <button>
                                    Go To Gallery
                                    </button>
                            </div>
                            <img src={Swiper1} alt={'wedding-cart'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <div>
                                    <img src={WeddingRing} alt={'wedding-cart'}/>
                                    <span>
                                    Toomar <span>Wedding</span> Invitation
                                    </span>
                                    <p>
                                    The Largest Online store for buying Wedding Invitation and Wedding Items
                                    </p>
                                    <button>
                                    Go To Gallery
                                    </button>
                            </div>
                            <img src={Swiper3} alt={'wedding-cart'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                
               
                
               {/* {data?.map(item => (
                   <SwiperSlide>
                       <Link to={`/gallery`}>
                            <div className={'toomarCardsSwiperItemContainer'}>
                                <div>
                                        <img src={WeddingRing} alt={'weddingRing'}/>
                                        <span>
                                            {item.title}
                                        </span>
                                        <p>
                                            {item.description}
                                        </p>
                                        <button>
                                            رفتن به گالری محصولات
                                        </button>
                                </div>
                                <img src={item?.image} alt={'wedding-cart'}/>
                            </div>
                        </Link>
                    </SwiperSlide>
               ))} */}
            </Swiper>
        </div>
    )
}

export default ToomarCardsSwiper;