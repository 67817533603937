import Confirmation from '../../assets/confirmation.svg';
import FastDelivery from '../../assets/fastDelivery.svg';
import Gift from '../../assets/gift.svg';
import PriceTag from '../../assets/priceTag.svg';
import CreditCard from '../../assets/creditCard.svg';
import GuaranteeCertificate from '../../assets/guaranteeCertificate.svg';

export const WhyItemsObject = [
    {
        id: 0,
        src: Confirmation,
        title: 'Proofreading Before Printing',
        description: 'At Toomar, we want to ensure the highest quality final product and your satisfaction. Therefore, we require your final approval before printing your wedding invitation.'
    },
    {
        id: 1,
        src: Gift,
        title: 'Toomar Discounts and Gifts Discounts',
        description: 'Special discounts on popular products (ranging from 10% to 70%).'
    },
    {
        id: 2,
        src: FastDelivery,
        title: 'Fast Shipping',
        description: 'Preparation Time: 4 to 7 business days, Shipping Time: 10 to 20 business days (depending on the country and the international postal company)'
    },
    {
        id: 3,
        src: PriceTag,
        title: 'Final Price of Cards',
        description: 'Final Price: All prices listed on the Toomar website are the final prices for cards and products.'
    },
    {
        id: 4,
        src: GuaranteeCertificate,
        title: 'Easy and Secure Shopping',
        description: 'Easy Selection: The Toomar website is designed to allow you to easily choose the card and product you want'
    },
    {
        id: 5,
        src: CreditCard,
        title: 'Guaranteeing the quality and quality of products',
        description: 'The meaning of the type and quality of the cards is that we guarantee the type and quality of all the cards on the site'
    },

]