import React from 'react';
import { Link } from 'react-router-dom';
import { WhyItemsObject } from '../Utils/constants';
import Title from '../Utils/Title';
import './Home.scss';

function WhyToomar() {
    return (
        <div className={'whyItemsContainer'}>
            <Title blackText={'Toomar?'} goldText={'Why Choose'}/>
            <div>
            {WhyItemsObject.map(item => (
                <Link to={'/why-toomar/'}>
                    <div key={item.id}>
                        <img src={item.src} alt={`${item.src}`}/>
                        <p>{item.title}</p>
                        <span>{item.description}</span>
                    </div>
                </Link>
            ))}
            </div>
        </div>
    )
}

export default WhyToomar;