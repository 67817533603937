import React from 'react';
import './Kits.scss';
import Telegram from '../../assets/telegram.svg';
import Whatsapp from '../../assets/whatsapp.svg';
import Instagram from '../../assets/instagram.svg';

function FollowUsInSocials() {
    return (
        <div className='followUsInSocialsContainer'>
            <p>
            Follow us on social media          
            </p>
            <div>
                <a href={'http://t.me/toomar_aroosicard'} target={'_blank'}>
                    <img src={Telegram} alt={'telegram'}/>
                </a>
                <a href={'http://wa.me/989057837887'} target={'_blank'}>
                    <img src={Whatsapp} alt={'whatsapp'}/>
                </a>
                <a href={'https://www.instagram.com/toomar_aroosicard/'} target={'_blank'}>
                    <img src={Instagram} alt={'instagram'}/>
                </a>
            </div>
        </div>
    )
}

export default FollowUsInSocials;