import ToomarUnderlineSmooth from "../../assets/toomarUnderlineSmooth.svg";

export default function GalleryOrder({ onClick, text, active, icon }) {
  return (
    <div onClick={onClick}>
      <p className={active ? "isActive" : "isNotActive"}>{text}</p>
      {active && <img src={ToomarUnderlineSmooth} alt={"wedding-cart"} /> }
      <div></div>
    </div>
  );
}
