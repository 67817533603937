import React, { useState } from "react";
import View from "../../assets/eye.svg";
import Edit from "../../assets/edit.svg";
import EditWhite from "../../assets/editWhite.svg";
import RadioButtonWithOrangeTick from "../kit/RadioButtonWithOrangeTick";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Close from "../../assets/circleClose.svg";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getCardTitles } from "../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1100px",
    maxWidth: "1200px!important",
    // height: 'var(--dialog-height)',
    borderRadius: "24px",
    "@media (max-width:800px)": {
      width: "100%",
      margin: "0px !important",
    },
  },
}));

function AcceptData({ onFinished, setStep, ...props }) {
  const [acceptLaw, setAcceptLaw] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const classes = useStyles();
  const params = useParams();

  const handleGoEdit = () => {
    if (openDetailsModal == "textDetails") {
      setStep(1);
      props.setJustThisStep(true);
    } else if (openDetailsModal == "titleDetails") {
      setStep(2);
      props.setJustThisStep(true);
    } else {
      setStep(3);
      props.setJustThisStep(true);
    }
  };
  return (
    <div className={"acceptDataContainer"}>
      <Dialog
        open={
          openDetailsModal == "textDetails" ||
          openDetailsModal == "titleDetails" ||
          openDetailsModal == "dataDetails"
        }
        hideBackdrop={false}
        onClose={() => setOpenDetailsModal("")}
        PaperProps={{
          className: classes.root,
        }}
      >
        <div className={"acceptDetailsReview"}>
          <div className={"header"}>
            <p>
              {openDetailsModal == "textDetails"
                ? "Text preview"
                : openDetailsModal == "titleDetails"
                ? "Title and print type preview"
                : "Information preview"}
            </p>
            <div onClick={() => setOpenDetailsModal("")}>
              <p>Close</p>
              <img src={Close} />
            </div>
          </div>
          {openDetailsModal == "textDetails" ? (
            props.chooseText !== null ? (
              <img className={"cardTextImage"} src={props.chooseText.image} />
            ) : (
              <p>{props.customText}</p>
            )
          ) : openDetailsModal == "titleDetails" ? (
            <div className={"titleDetailsContainer"}>
              <div>
                <p>Print type</p>
                <button>
                  {props.typeOfPrint == 1 ? "Colorful" : "Bulging"}
                </button>
              </div>
              <div>
                {props.buyingProducts[params.id - 1].data.has_latin}
                <p>Color</p>
                <button
                  className={
                    props.typeOfPlan == 0
                      ? "black"
                      : props.typeOfPlan == 1
                      ? "brown"
                      : props.typeOfPlan == 2
                      ? "red"
                      : props.typeOfPlan == 3
                      ? "yellow"
                      : props.typeOfPlan == 4
                      ? "gray"
                      : props.typeOfPlan == 5
                      ? "gold"
                      : props.typeOfPlan == 6
                      ? "silver"
                      : "silver"
                  }
                >
                  {props.typeOfPlan == 0
                    ? "Black"
                    : props.typeOfPlan == 1
                    ? "Brown"
                    : props.typeOfPlan == 2
                    ? "Red"
                    : props.typeOfPlan == 3
                    ? "Yellow"
                    : props.typeOfPlan == 4
                    ? "White for plexiglass cards"
                    : props.typeOfPlan == 5
                    ? "goldsmith"
                    : props.typeOfPlan == 6
                    ? "silver hammer"
                    : "silver hammer"}
                </button>
              </div>
              <div
                style={
                  props.buyingProducts[params.id - 1]?.data.has_latin !== true
                    ? { margin: "0px" }
                    : null
                }
              >
                {props.buyingProducts[params.id - 1]?.data.has_latin ==
                  true && (
                  <>
                    <p>Latin print type</p>
                    <button
                      className={
                        props.typeOfPrintForLatin == 0
                          ? "black"
                          : props.typeOfPrintForLatin == 5
                          ? "gold"
                          : props.typeOfPrintForLatin == 6
                          ? "silver"
                          : "silver"
                      }
                    >
                      {props.typeOfPrintForLatin == 0
                        ? "Black"
                        : props.typeOfPrintForLatin == 5
                        ? "Gold"
                        : props.typeOfPrintForLatin == 6
                        ? "Silver"
                        : "Silver"}
                    </button>
                  </>
                )}
              </div>
              <div>
                <p>Title type</p>
                <img src={props.typeOfTitle.image} />
              </div>
            </div>
          ) : (
            <div className={"dataDetailsContainer"}>
              <p>Information of the bride</p>
              <div>
                <p>First name</p>
                <p>{props.womanName}</p>
                <p>Last name</p>
                <p>{props.womanFamilyName}</p>
              </div>
              <p>Information of the groom</p>
              <div>
                <p>First name</p>
                <p>{props.manName}</p>
                <p>Last name</p>
                <p>{props.manFamilyName}</p>
              </div>
              <div>
                <p>Ceremony from the clock</p>
                <p>{`${props.startHour}:${props.startMin}`}</p>
                <p>Ceremony to the clock</p>
                <p>{`${props.endHour}:${props.endMin}`}</p>
                <p>Ceremony date:</p>
                <p>{`${props.year}/${props.month}/${props.day}`}</p>
              </div>
              <div>
                <p>Name priority on the card</p>
                <p>
                  {props.whoIsFirst == 1
                    ? "First the bride, then the groom"
                    : "First the groom, then the bride"}
                </p>
              </div>
              <div>
                <p>Ceremony address</p>
                <p>{props.address}</p>
              </div>
            </div>
          )}
          <button onClick={handleGoEdit}>
            <img src={EditWhite} />
            <p>Edit</p>
          </button>
        </div>
      </Dialog>
      <p>Please check and confirm the correctness of the information.</p>
      <div>
        <div>
          <p>Information registration steps</p>
          <p>Show</p>
          <p>Edit</p>
        </div>
        <div>
          <p>Select text</p>
          <div>
            <img
              onClick={() => setOpenDetailsModal("textDetails")}
              src={View}
              alt={"view"}
            />
          </div>
          <div
            onClick={() => {
              setStep(1);
              props.setJustThisStep(true);
            }}
          >
            <img src={Edit} alt={"edit"} />
          </div>
        </div>
        {props.product.data.is_digital == true ? null : (
          <div>
            <p>Title and print type</p>
            <div onClick={() => setOpenDetailsModal("titleDetails")}>
              <img src={View} alt={"view"} />
            </div>
            <div
              onClick={() => {
                setStep(2);
                props.setJustThisStep(true);
              }}
            >
              <img src={Edit} alt={"edit"} />
            </div>
          </div>
        )}
        <div>
          <p>Information</p>
          <div onClick={() => setOpenDetailsModal("dataDetails")}>
            <img src={View} alt={"view"} />
          </div>
          <div
            onClick={() => {
              setStep(3);
              props.setJustThisStep(true);
            }}
          >
            <img src={Edit} alt={"edit"} />
          </div>
        </div>
      </div>
      <p>Toomar Guarantee and Customer Satisfaction</p>
      <p>
        <span style={{ fontFamily: "sans-serif" }}>1</span>-Exact Match to
        Sample Image: Toomar guarantees that the final product you receive will
        match the sample image you see on our website, based on the information
        you provide.
      </p>
      <p>
        <span style={{ fontFamily: "sans-serif" }}>2</span>. Pre-Printing
        Proofreading: Before printing your product, our dedicated support team
        will contact you via Telegram, WhatsApp, or another method using your
        registered phone number. We will send you a sample of the designed text
        for your review and approval before printing or producing the final
        product. This ensures that any necessary changes can be made to your
        satisfaction. We understand your concerns about errors in text,
        information, or printing quality, and we want you to be completely
        satisfied with the final product.
      </p>
      <p>
        <span style={{ fontFamily: "sans-serif" }}>3</span>.{" "}
        <span style={{ fontFamily: "sans-serif" }}>48</span>-Hour Guarantee:
        Toomar takes responsibility for any discrepancies in quantity or damages
        to your product for up to{" "}
        <span style={{ fontFamily: "sans-serif" }}>48</span> hours after
        delivery.
      </p>
      <p>
        <span style={{ fontFamily: "sans-serif" }}>4</span>. Free Shipping:
        Enjoy free shipping on all orders over{" "}
        <span style={{ fontFamily: "sans-serif" }}>$1000</span>.
      </p>
      <p>
        <span style={{ fontFamily: "sans-serif" }}>5</span>. Production and
        Delivery: The production process for our products takes between{" "}
        <span style={{ fontFamily: "sans-serif" }}>4</span> and{" "}
        <span style={{ fontFamily: "sans-serif" }}>7</span> business days,
        depending on the material and type of product. Once your product is
        ready, it will be packaged and shipped via international mail. Delivery
        time varies depending on your country and location, but typically takes
        between <span style={{ fontFamily: "sans-serif" }}>10</span> and{" "}
        <span style={{ fontFamily: "sans-serif" }}>20</span> business days.
      </p>
      <p style={{ margin: "0px" }}></p>
      <p>In Conclusion:</p>
      <p>
        We are grateful for your trust in Toomar and for choosing our website
        for your purchase. Our team is dedicated to providing you with the best
        possible service and ensuring your complete satisfaction. We hope that
        our work will result in your complete satisfaction.
      </p>
      <p style={{ marginBottom: "4px" }}>Sincerely,</p>
      <p style={{ margin: "0px" }}>Erfan Mehri</p>
      <p>Founder of Toomar</p>
      <div>
        <RadioButtonWithOrangeTick
          checked={acceptLaw}
          onChange={() => setAcceptLaw(!acceptLaw)}
          title={"I have read and accept the rules and regulations of the site"}
        />
      </div>
      <div>
        <button onClick={() => setStep(3)}>Back</button>
        <button
          onClick={() =>
            acceptLaw == true
              ? onFinished()
              : toast.error("Accepting the rules is mandatory")
          }
        >
          Accept and continue
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  buyingProducts: state.counter.buyingProducts,
});

export default connect(mapStateToProps, { getCardTitles })(AcceptData);
