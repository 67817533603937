import React, { useState } from 'react';
import { sentTicket } from '../../redux/actions';
import ToomarContainer from '../containers/ToomarContainer';
import FollowUsInSocials from '../kit/FollowUsInSocials';
import ToomarInput from '../kit/ToomarInput';
import ToomarTextarea from '../kit/ToomarTextarea';
import Title from '../Utils/Title';
import './RegisterComplaints.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

function RegisterComplaints({...props}) {
    
    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');

    const handleSentTicket = () => {
        let data = {
            "phone_number": phoneNumber,
            "content": comment,
            "email": email,
            "name": name + " " + familyName,
            "subject": "ثبت شکایت"
        }
        props.sentTicket(data, () => {
            toast("Successfully submitted!");
            setName('');
            setFamilyName('');
            setPhoneNumber('');
            setEmail('');
            setComment('');
            },
            () => toast("There is something wrong with your request!"))
    }
    
    return (
        <ToomarContainer>
            <ToastContainer />
            <Title
            goldText={'Submit'}
            blackText={'Suggestion'}/>
            <div className={'registerComplaintsContainer'}>
                <p>
                Dear user, Toomar team tries its best to
provide you with the best services, your
constructive suggestions and criticisms wil greatly help the growth of our collection, thank you for taking the time to send your comments.

                </p>
                <div>
                    <ToomarInput
                    title={'First name'}
                    inputValue={name}
                    onChange={(e) => setName(e.target.value)}
                    />
                    <ToomarInput
                    title={'Last name'}
                    inputValue={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                    />
                </div>
                <div>
                    <ToomarInput
                    title={'Phone number'}
                    maxLength={11}
                    inputValue={phoneNumber}
                    // errorMessage={phoneNumber.length != 11 ? "تلفن همراه باید ۱۱ رقمی باشد" : ""}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <ToomarInput
                    title={'Email address'}
                    inputValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <ToomarTextarea
                    title={'Suggestion'}
                    inputValue={comment}
                    onChange={(e) => setComment(e.target.value)}
                    />
                </div>
                <div>
                    <button onClick={handleSentTicket}>
                        Submit suggestion
                    </button>
                </div>
                <FollowUsInSocials/>
            </div>
                
        </ToomarContainer>
    )
}

export default connect(null, { sentTicket })(RegisterComplaints);