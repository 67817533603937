import React from "react";
import { Link } from "react-router-dom";
import Telegram from "../../assets/telegram.svg";
import Whatsapp from "../../assets/whatsapp.svg";
import "./Footer.scss";
function Footer() {
  return (
    <div className={"footerContainer"}>
      <div>
        <div>
          <p>Quick Access</p>
          <Link to={"/contact-us"}>
            <p>Contact us</p>
          </Link>
          <Link to={"/offers-critics"}>
            <p>Suggestions</p>
          </Link>
          <Link to={"/laws"}>
            <p>Terms and Conditions</p>
          </Link>
          {/* <Link to={'/repetitive-questions'}>
                        <p>
                            سوالات متداول
                        </p>
                    </Link>
                    <Link to={'/order-guide'}>
                        <p>
                            راهنمای ثبت سفارش
                        </p>    
                    </Link>
                    <Link to={'/followup-order'}>
                        <p>
                            پیگیری سفارشات
                        </p>    
                    </Link> */}
        </div>
        <div>
          <Link to={"/repetitive-questions"}>
            <p>FAQ</p>
          </Link>
          <Link to={"/order-guide"}>
            <p>How To Order</p>
          </Link>
          <Link to={"/followup-order"}>
            <p>Order Tracking</p>
          </Link>
          {/* <p>
                        تماس با ما
                    </p> */}
          {/* <p> */}
          {/* تلفن :۰۲۱۴۴۱۳۷۱۷۹  */}
          {/* </p> */}
          {/* <p> */}
          {/* (زمان پاسخگویی تلفنی : هفت روز هفته  از ساعت 9 صبح تا 9 شب)  */}
          {/* (زمان پاسخگویی : هفت روز هفته  از ساعت 9 صبح تا 9 شب)                     */}
          {/* </p>
                    <div>
                        <a href={'http://t.me/toomar_aroosicards'} target={'_blank'}>
                            <img src={Telegram} alt={'telegram'}/>
                        </a>
                        <a href={'http://wa.me/989054816441'} target={'_blank'}>
                            <img src={Whatsapp} alt={'whatsapp'}/>
                        </a>
                    </div>
                    <Link to={'/repetitive-questions/'}>
                        روش ارسال
                    </Link> */}
        </div>
        <div>
          <p>Toomar Online shop</p>
          <p>
            Tomar online store, as the largest online store of wedding cards and
            items, is at your service, which offers all the items related to the
            wedding online, this store started its activity in{" "}
            <span style={{ fontFamily: "sans-serif" }}>2017</span>.
          </p>
          <div></div>
        </div>
      </div>
      <p>
        ©<span style={{ fontFamily: "sans-serif" }}>2024</span> Toomar.co, Inc .
        or Its affiliates
      </p>
    </div>
  );
}

export default Footer;
