import React, { useEffect, useState } from "react";
import ToomarContainer from "../containers/ToomarContainer";
import "./BuyProcess.scss";
import Tick from "../../assets/tick.svg";
import SelectText from "./SelectText";
import SelectTitle from "./SelectTitle";
import AddData from "./AddData";
import AcceptData from "./AcceptData";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setFinishedProductDetails } from "../../redux/actions";

function BuyProcess({ ...props }) {
  const [step, setStep] = useState(1);
  const params = useParams();
  const [chooseText, setChooseText] = useState("");
  const [customText, setCustomText] = useState("");
  const [typeOfTitle, setTypeOfTitle] = useState("");
  const [typeOfPrintForLatin, setTypeOfPrintForLatin] = useState("");
  const [typeOfPlan, setTypeOfPlan] = useState(0);
  const [typeOfPrint, setTypeOfPrint] = useState(2);
  const [womanName, setWomanName] = useState("");
  const [womanFamilyName, setWomanFamilyName] = useState("");
  const [womanLatinName, setWomanLatinName] = useState("");
  const [manName, setManName] = useState("");
  const [manFamilyName, setManFamilyName] = useState("");
  const [manLatinName, setManLatinName] = useState("");
  const [whoIsFirst, setWhoIsFirst] = useState(1);
  const [isToNight, setIsToNight] = useState(false);
  const [startHour, setStartHour] = useState(12);
  const [startMin, setStartMin] = useState(0);
  const [endHour, setEndHour] = useState(12);
  const [endMin, setEndMin] = useState(0);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [address, setAddress] = useState("");
  const [songName, setSongName] = useState("");
  const [artistName, setArtistName] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [justThisStep, setJustThisStep] = useState(false);
  const search = useLocation().search;
  const queryStep = new URLSearchParams(search).get("step");
  useEffect(() => {
    for (let i = 0; i < props.finishedProductDetails.length; i++) {
      if (
        props.buyingProducts[params.id - 1] &&
        props.finishedProductDetails[i].product ==
          props.buyingProducts[params.id - 1].data.id
      ) {
        setIsEdit(i);
        setManName(props.finishedProductDetails[i].info.groom_firstname);
        setManFamilyName(props.finishedProductDetails[i].info.groom_lastname);
        setManLatinName(props.finishedProductDetails[i].info.groom_latinname);
        setWomanName(props.finishedProductDetails[i].info.bride_firstname);
        setWomanFamilyName(props.finishedProductDetails[i].info.bride_lastname);
        setWomanLatinName(props.finishedProductDetails[i].info.bride_latinname);
        setWhoIsFirst(props.finishedProductDetails[i].info.taghadom ? 1 : 2);
        setDay(props.finishedProductDetails[i].info.celeb_date.day);
        setMonth(props.finishedProductDetails[i].info.celeb_date.month);
        setYear(props.finishedProductDetails[i].info.celeb_date.year);
        setStartHour(props.finishedProductDetails[i].info.timeFrom.hour);
        setStartMin(props.finishedProductDetails[i].info.timeFrom.minute);
        setEndHour(props.finishedProductDetails[i].info.timeTill.hour);
        setEndMin(props.finishedProductDetails[i].info.timeTill.minute);
        setIsToNight(props.finishedProductDetails[i].info.pasiAzShab);
        setAddress(props.finishedProductDetails[i].info.talar_address);
        setChooseText(
          props.finishedProductDetails[i].text === undefined
            ? ""
            : props.finishedProductDetails[i].text === null
            ? null
            : { id: props.finishedProductDetails[i].text }
        );
        setCustomText(props.finishedProductDetails[i].text_custom);
        setTypeOfTitle(
          props.finishedProductDetails[i].title === undefined
            ? ""
            : { id: props.finishedProductDetails[i].title }
        );
        setTypeOfPrint(
          parseInt(props.finishedProductDetails[i].koob) < 5 ? 1 : 2
        );
        setTypeOfPlan(parseInt(props.finishedProductDetails[i].koob));
        setTypeOfPrintForLatin(parseInt(props.finishedProductDetails[i].latin));
      }
    }
    if (
      props.buyingProducts[params.id - 1] &&
      props.buyingProducts[params.id - 1].data &&
      props.buyingProducts[params.id - 1].data.is_koob == true
    ) {
      setTypeOfPrint(2);
    }
    if (queryStep == 3) {
      setStep(3);
    }
  }, props.buyingProducts);
  const handleSubmitDetails = () => {
    var data = {
      type:
        props.buyingProducts[params.id - 1].data.is_card == true
          ? "card"
          : props.buyingProducts[params.id - 1].data.is_digital == true
          ? "digital"
          : "other",
      product: props.buyingProducts[params.id - 1].data.id,
      text:
        chooseText != "" && chooseText != null
          ? chooseText.id
          : chooseText === null
          ? null
          : "",
      text_custom: customText,
      title: typeOfTitle != "" ? typeOfTitle.id : "",
      count: props.buyingProducts[params.id - 1].count,
      options: props.buyingProducts[params.id - 1].options,
      koob: typeOfPlan, //(0, 'مشکی'), (1, 'قهوه‌ای'), (2, 'قرمز'), (3, 'زرد'), (4, 'سفید'), (5, 'طلا کوب'), (6, 'نقره کوب')
      latin: typeOfPrintForLatin,
      info: {
        titrazh: "",
        groom_firstname: manName,
        groom_lastname: manFamilyName,
        groom_latinname: manLatinName,
        bride_firstname: womanName,
        bride_lastname: womanFamilyName,
        bride_latinname: womanLatinName,
        taghadom: whoIsFirst == 1 ? "bride" : "groom", // esme aroos aval bashe ya damad
        celeb_date: {
          day: day,
          month: month,
          year: year,
        },
        timeFrom: {
          hour: startHour,
          minute: startMin,
          second: 0,
        },
        timeTill: {
          hour: endHour,
          minute: endMin,
          second: 0,
        },
        pasiAzShab: isToNight,
        talar_address: address,
      },
    };
    if (isEdit !== "") {
      let locFinishedProducts = [];
      locFinishedProducts = [...props.finishedProductDetails];
      locFinishedProducts[isEdit] = data;
      props.setFinishedProductDetails([...locFinishedProducts]);
      window.location.replace("/cart");
    } else {
      props.setFinishedProductDetails([...props.finishedProductDetails, data]);
      window.location.replace("/cart");
    }
  };
  return (
    <ToomarContainer>
      <div style={{ position: "absolute", top: 0 }} />
      <ToastContainer />
      <div className={"buyProcessContainer"}>
        <p>Submit information</p>
        {queryStep != 3 ? (
          <>
            <div
              className={
                props.buyingProducts[params.id - 1]?.data?.is_digital == true
                  ? "progressBarContainerForBuyProcessDigital"
                  : "progressBarContainerForBuyProcess"
              }
            >
              <div>
                <div style={step >= 2 ? { backgroundColor: "#39B93A" } : null}>
                  <img src={Tick} alt={"tick"} />
                </div>
                <div
                  style={step >= 2 ? { backgroundColor: "#39B93A" } : null}
                />
                {props.buyingProducts[params.id - 1]?.data?.is_digital ==
                true ? null : (
                  <>
                    <div
                      style={step >= 3 ? { backgroundColor: "#39B93A" } : null}
                    >
                      <img src={Tick} alt={"tick"} />
                    </div>
                    <div
                      style={step >= 3 ? { backgroundColor: "#39B93A" } : null}
                    />
                  </>
                )}
                <div style={step >= 4 ? { backgroundColor: "#39B93A" } : null}>
                  <img src={Tick} alt={"tick"} />
                </div>
                <div
                  style={step >= 4 ? { backgroundColor: "#39B93A" } : null}
                />
                <div style={step >= 5 ? { backgroundColor: "#39B93A" } : null}>
                  <img src={Tick} alt={"tick"} />
                </div>
              </div>
              <div>
                <p>Select text</p>
                {props.buyingProducts[params.id - 1]?.data?.is_digital ==
                true ? null : (
                  <>
                    <p>Print type</p>
                  </>
                )}
                <p>Card information</p>
                <p>Accept information</p>
              </div>
            </div>
          </>
        ) : null}
        {step == 1 ? (
          <SelectText
            chooseText={chooseText}
            setChooseText={(value) => setChooseText(value)}
            justThisStep={justThisStep}
            setJustThisStep={(value) => setJustThisStep(value)}
            customText={customText}
            setCustomText={(value) => setCustomText(value)}
            setStep={(value) => {
              setStep(value);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        ) : step == 2 ? (
          <SelectTitle
            typeOfTitle={typeOfTitle}
            setTypeOfTitle={(value) => setTypeOfTitle(value)}
            typeOfPrintForLatin={typeOfPrintForLatin}
            setTypeOfPrintForLatin={(value) => setTypeOfPrintForLatin(value)}
            typeOfPlan={typeOfPlan}
            setTypeOfPlan={(value) => setTypeOfPlan(value)}
            typeOfPrint={typeOfPrint}
            setTypeOfPrint={(value) => setTypeOfPrint(value)}
            justThisStep={justThisStep}
            setJustThisStep={(value) => {
              setJustThisStep(value);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            setStep={(value) => {
              setStep(value);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        ) : step == 3 ? (
          <AddData
            womanName={womanName}
            setWomanName={(value) => setWomanName(value)}
            womanFamilyName={womanFamilyName}
            setWomanFamilyName={(value) => setWomanFamilyName(value)}
            womanLatinName={womanLatinName}
            setWomanLatinName={(value) => setWomanLatinName(value)}
            manName={manName}
            setManName={(value) => setManName(value)}
            manFamilyName={manFamilyName}
            setManFamilyName={(value) => setManFamilyName(value)}
            manLatinName={manLatinName}
            setManLatinName={(value) => setManLatinName(value)}
            whoIsFirst={whoIsFirst}
            setWhoIsFirst={(value) => setWhoIsFirst(value)}
            isToNight={isToNight}
            setIsToNight={(value) => setIsToNight(value)}
            startHour={startHour}
            setStartHour={(value) => setStartHour(value)}
            startMin={startMin}
            setStartMin={(value) => setStartMin(value)}
            endHour={endHour}
            setEndHour={(value) => setEndHour(value)}
            endMin={endMin}
            setEndMin={(value) => setEndMin(value)}
            address={address}
            setAddress={(value) => setAddress(value)}
            songName={songName}
            setSongName={(value) => setSongName(value)}
            artistName={artistName}
            setArtistName={(value) => setArtistName(value)}
            day={day}
            setDay={(value) => setDay(value)}
            month={month}
            setMonth={(value) => setMonth(value)}
            year={year}
            setYear={(value) => setYear(value)}
            justThisStep={justThisStep}
            setJustThisStep={(value) => {
              setJustThisStep(value);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            setStep={(value) => {
              setStep(value);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            onFinished={handleSubmitDetails}
          />
        ) : (
          <AcceptData
            chooseText={chooseText}
            customText={customText}
            typeOfTitle={typeOfTitle}
            typeOfPrintForLatin={typeOfPrintForLatin}
            typeOfPlan={typeOfPlan}
            typeOfPrint={typeOfPrint}
            womanName={womanName}
            womanFamilyName={womanFamilyName}
            womanLatinName={womanLatinName}
            manName={manName}
            manFamilyName={manFamilyName}
            manLatinName={manLatinName}
            whoIsFirst={whoIsFirst}
            isToNight={isToNight}
            startHour={startHour}
            startMin={startMin}
            endHour={endHour}
            endMin={endMin}
            address={address}
            songName={songName}
            artistName={artistName}
            day={day}
            month={month}
            year={year}
            justThisStep={justThisStep}
            setJustThisStep={(value) => setJustThisStep(value)}
            onFinished={handleSubmitDetails}
            product={props.buyingProducts[params.id - 1]}
            setStep={(value) => {
              setStep(value);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        )}
      </div>
    </ToomarContainer>
  );
}

const mapStateToProps = (state) => ({
  buyingProducts: state.counter.buyingProducts,
  finishedProductDetails: state.counter.finishedProductDetails,
});

export default connect(mapStateToProps, { setFinishedProductDetails })(
  BuyProcess
);
