import React from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import Title from '../Utils/Title';
import './WhyToomar.scss';
import FollowUsInSocials from '../kit/FollowUsInSocials';
import { WhyItemsObject } from '../Utils/constants';

function WhyToomarPage() {
    return (
        <ToomarContainer>
            <Title
            goldText={'Why '}
            blackText={'Toomar'}/>
            <div className={'whyToomarContainer'}>
                <div>
                    <div>
                        <img src={WhyItemsObject[0].src} alt={'confirmation'}/>
                        <p>
                            {WhyItemsObject[0].title}
                        </p>
                    </div>
                    <p>
                    What is Proofreading Before Printing?

                    At Toomar, we want to ensure the highest quality final product and your satisfaction. Therefore, we require your final approval before printing your wedding invitation.

                    Proofreading Process:

                    Place your wedding invitation order and complete the payment process.
                    Receive a message from the Toomar support team via Telegram or WhatsApp.
                    Review the designed invitation text sent by the team.
                    Request any changes or edits you need.
                    Provide final approval for the text.
                    The text will then proceed to the printing and cliche stages.
                    Printing Without Final Approval:

                    We will not print any cards without your final approval.

                    </p>
                </div>
                <div>
                    <div>
                        <img src={WhyItemsObject[1].src} alt={'offsAndGifts'}/>
                        <p>
                            {WhyItemsObject[1].title}
                        </p>
                    </div>
                    <p>
                    Special discounts on popular products (ranging from 10% to 70%).
                    Follow Toomar's Instagram page for updates on discounts.
                    Gifts:

                    Various raffles held on different occasions.
                    Valuable prizes for raffle winners.


                    </p>
                </div>
                <div>
                    <div>
                        <img src={WhyItemsObject[2].src} alt={'fastSend'}/>
                        <p>
                            {WhyItemsObject[2].title}
                        </p>
                    </div>
                    <p>
                    Preparation Time:

                    4 to 7 business days

                    Shipping Time:

                    10 to 20 business days (depending on the country and the international postal company)

                    </p>
                </div>
                <div>
                    <div>
                        <img src={WhyItemsObject[3].src} alt={'finalPrice'}/>
                        <p>
                            {WhyItemsObject[3].title}
                        </p>
                    </div>
                    <p>
                    Final Price:

                    All prices listed on the Toomar website are the final prices for cards and products.

                    Additional Charges:

                    The price will only increase if you choose additional options or embossed printing.
                    </p>
                </div>
                <div>
                    <div>
                        <img src={WhyItemsObject[4].src} alt={'easyBuy'}/>
                        <p>
                            {WhyItemsObject[4].title}
                        </p>
                    </div>
                    <p>
                    Easy Selection:

                    The Toomar website is designed to allow you to easily choose the card and product you want.

                    Online Ordering:

                    You can complete all the steps involved in a traditional order online.

                    Order Guide:

                    For instructions on how to order cards, you can refer to the Order Guide section.

                    Trusting Toomar:

                    You can confidently entrust your event to Toomar and focus on other wedding preparations
                    </p>
                </div>
                <div>
                    <div>
                        <img src={WhyItemsObject[5].src} alt={'guarantee'}/>
                        <p>
                            {WhyItemsObject[5].title}
                        </p>
                    </div>
                    <p>
                        {WhyItemsObject[5].description}
                    </p>
                </div>
                <FollowUsInSocials/>
            </div>
                
        </ToomarContainer>
    )
}

export default WhyToomarPage;