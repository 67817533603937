import React, { useEffect, useState } from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import './Favorite.scss';
import { connect } from 'react-redux';
import { setFavoriteProducts } from '../../redux/actions';
import { priceFormat } from '../Utils/PriceFormat';
import Delete from '../../assets/delete.svg';
import FavoriteCard from './FavoriteCard';

function Favorite({...props}) {

    const deleteFavorite = (product) => {
        let index = props.favoriteProducts.findIndex(item => item.id == product.id);
        let locObject = [...props.favoriteProducts];
        locObject.splice(index, 1);
        props.setFavoriteProducts([...locObject]);
    }

    return (
        <ToomarContainer>
            <div className='favoritesContainer'>
                <p>
                    Favorites
                </p>
                <div>
                    {/* {props.favoriteProducts.map(item => (
                        <FavoriteCard value={item} onDeleteFromFav={(data) => deleteFavorite(data)}/> */}
                    <div>
                        {props.favoriteProducts.map((item, index) =>
                        index % 3 == 0 ? (
                            <FavoriteCard key={item.id} value={item} onDeleteFromFav={(data) => deleteFavorite(data)}/>
                        ) : null
                        )}
                    </div>
                    <div>
                        {props.favoriteProducts.map((item, index) =>
                        index % 3 == 1 ? (
                            <FavoriteCard key={item.id} value={item} onDeleteFromFav={(data) => deleteFavorite(data)}/>
                        ) : null
                        )}
                    </div>
                    <div>
                        {props.favoriteProducts.map((item, index) =>
                        index % 3 == 2 ? (
                            <FavoriteCard key={item.id} value={item} onDeleteFromFav={(data) => deleteFavorite(data)}/>
                        ) : null
                        )}
                    </div>
                    {/* ))} */}
                </div>
            </div>
        </ToomarContainer>
    )
}

const mapStateToProps = (state) => ({
    favoriteProducts: state.counter.favoriteProducts,
})

export default connect(mapStateToProps, { setFavoriteProducts })(Favorite);