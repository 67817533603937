

export const orderings = [
  {
    text: "Most Popular",
    value: "-score",
  },
  {
    text: "Newest",
    value: "-created-at",
  },
  {
    text: "Most Expensive",
    value: "-price",
  },
  {
    text: "Cheapest",
    value: "price",
  },
]