import React from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import Title from '../Utils/Title';
import './Laws.scss';
import FollowUsInSocials from '../kit/FollowUsInSocials';

function Laws() {
    return (
        <ToomarContainer>
            <Title
            goldText={'Laws'}
            blackText={''}/>
            <div className={'lawsContainer'}>
            
            <p>
            <span style={{fontFamily: "sans-serif"}}>1</span>-Exact Match to Sample Image:
            Toomar guarantees that the final product you receive will match the sample image you see on our website, based on the information you provide.


            </p>
            <p>
            <span style={{fontFamily: "sans-serif"}}>2</span>. Pre-Printing Proofreading:

Before printing your product, our dedicated support team will contact you via Telegram, WhatsApp, or another method using your registered phone number. We will send you a sample of the designed text for your review and approval before printing or producing the final product. This ensures that any necessary changes can be made to your satisfaction. We understand your concerns about errors in text, information, or printing quality, and we want you to be completely satisfied with the final product.
            </p>
            <p>
            <span style={{fontFamily: "sans-serif"}}>3</span>. <span style={{fontFamily: "sans-serif"}}>48</span>-Hour Guarantee:

Toomar takes responsibility for any discrepancies in quantity or damages to your product for up to <span style={{fontFamily: "sans-serif"}}>48</span> hours after delivery.

            </p>
            <p>
            <span style={{fontFamily: "sans-serif"}}>4</span>. Free Shipping:

Enjoy free shipping on all orders over <span style={{fontFamily: "sans-serif"}}>$1000</span>.


            </p>
            <p>
            <span style={{fontFamily: "sans-serif"}}>5</span>. Production and Delivery:

The production process for our products takes between <span style={{fontFamily: "sans-serif"}}>4</span> and <span style={{fontFamily: "sans-serif"}}>7</span> business days, depending on the material and type of product. Once your product is ready, it will be packaged and shipped via international mail. Delivery time varies depending on your country and location, but typically takes between <span style={{fontFamily: "sans-serif"}}>10</span> and <span style={{fontFamily: "sans-serif"}}>20</span> business days.

            </p>
            <p style={{margin: "0px"}}>
            
            </p>
            <p>
            In Conclusion:

            </p>
            <p>
            We are grateful for your trust in Toomar and for choosing our website for your purchase. Our team is dedicated to providing you with the best possible service and ensuring your complete satisfaction. We hope that our work will result in your complete satisfaction.


            </p>
            <p style={{marginBottom: "4px"}}>
            Sincerely,

            </p>
            <p style={{margin: "0px"}}>
            Erfan Mehri
            </p>
            <p>
            Founder of Toomar
            </p>
                
                <FollowUsInSocials/>
            </div>
                
        </ToomarContainer>
    )
}

export default Laws;