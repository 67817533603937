import React from "react";
import "./Utils.scss";
import ArrowLeft from "../../assets/arrowLeftLong.svg";
import { Link } from "react-router-dom";

function ToomarBlogCard({ data, ...props }) {
  return (
    <Link to={`/magazine/${data.id}`}>
      <div className={"toomarBlogCardContainer"}>
        <img src={data.image} alt={"wedding-cart"} />
        <p>{data.title}</p>
        <div className={"more"}>
          <span>See more</span>
          <img src={ArrowLeft} alt={"more"} />
        </div>
        <div className={"date"}>20 April</div>
      </div>
    </Link>
  );
}

export default ToomarBlogCard;
