import React from 'react';
import './Home.scss';
import Test from '../../assets/test2.svg';
import { Link } from 'react-router-dom';
function TopFeatureOfToomar() {
    return (
            <div className={'topFeatureOfToomarContainer'}>
                <div>
                    <p>
                    The Prices In Toomar Are The Final Price 
                    </p>
                    <span>
                    Worldwide Shipping
                    </span>
                    <span className={'leftSpan'}>
                    The Prices In Toomar Are The Final Price 
                    </span>
                    <Link to={'/gallery'}>
                        <button>
                        Ordering Wedding Invitation
                        </button>
                    </Link>

                </div>
                <img src={Test} alt={'wedding-cart'}/>
            </div>
    )
}

export default TopFeatureOfToomar;